<template>
  <div class="staff-working-dates">
    <select-box-form
      class="select-box-form"
      :selectData="selectData"
      v-model="mixinInputData.deadline"
      >締め日</select-box-form
    >
    <div class="contents-area">
      <div class="shift-table-wrapper">
        <shift-table-wrapper
          :staffWorkingDates="mixinInputData.staffWorkingDates"
          :yearMonth="yearMonth"
          :days="days"
          :deadline="mixinInputData.deadline"
          :diffFlg="mixinDiffFlg"
          @back="backMonth"
          @next="nextMonth"
          @click="changeShiftId"
        />
      </div>
      <div class="heading">
        <div class="text">シフト登録</div>
        <hint-balloon :note="note" />
      </div>
      <hr />
      <div class="shift-select-wrapper">
        <shift-select-wrapper
          :shiftId="shiftId"
          :yearMonth="yearMonth"
          @input="selectShift"
          @bulk-registration="bulkRegistrationShiftIds"
          @bulk-clear="bulkClearShiftIds"
        />
      </div>
      <base-button-medium-orange
        @click="updateStaffWorkingDates"
        v-if="lookOnlyFlg === 0"
        :disabled="waitFlg"
        class="button"
        >登録</base-button-medium-orange
      >
    </div>
    <announce-popup
      v-if="popupFlg"
      @close="popupFlg = !popupFlg"
      :type="type"
      :title="title"
      :leftAlignMessage="leftAlignMessage"
      :buttons="buttons"
      :popupStyle="popupStyle"
      >{{ popupMessage }}</announce-popup
    >
    <unsaved-leave-popup :popupFlg="mixinDiffFlg" />
  </div>
</template>

<script>
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import ShiftTableWrapper from '@/components/parts/organisms/ShiftTableWrapper'
import ShiftSelectWrapper from '@/components/parts/organisms/ShiftSelectWrapper'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
export default {
  name: 'StaffWorkingDates',

  components: {
    SelectBoxForm,
    ShiftTableWrapper,
    ShiftSelectWrapper,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    HintBalloon
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      staffWorkingDates: [],
      initialStaffWorkingDates: [],
      yearMonth: {},
      shiftId: 0,
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      leftAlignMessage: '',
      buttons: ['閉じる'],
      popupStyle: {},
      defaultShiftId: 0,
      waitFlg: false,
      note: `下のシフトパターンを選択してください。\n個別登録：登録したいスタッフの日付欄を押すとシフトを個別登録できます。\n一括登録：「一括登録」ボタンを押すと、全員のシフトを一括登録できます。`
    }
  },

  computed: {
    ...mapGetters({
      staffs: 'staffs/getStaffs',
      getShiftPattern: 'shiftPatterns/getDataById',
      publicHolidays: 'master/getPublicHolidays',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    days() {
      const daysInMonth = moment(this.yearMonth).daysInMonth()
      const daysInPreMonth = moment(this.yearMonth)
        .subtract(1, 'M')
        .daysInMonth()
      const deadlineDay =
        this.mixinInputData.deadline <= daysInMonth
          ? moment(
              moment(this.yearMonth).format('YYYYMM') +
                this.mixinInputData.deadline.toString().padStart(2, '0'),
              'YYYYMMDD'
            )
          : moment(this.yearMonth).endOf('month')
      const repeatNum =
        this.mixinInputData.deadline <= daysInPreMonth &&
        this.mixinInputData.deadline <= daysInMonth //deadline28以下・deadline30の2月以外
          ? daysInPreMonth
          : daysInPreMonth < this.mixinInputData.deadline &&
            this.mixinInputData.deadline < daysInMonth //deadline29 閏年以外の2月以外
          ? this.mixinInputData.deadline
          : daysInPreMonth === 31 &&
            this.mixinInputData.deadline === 29 &&
            daysInMonth === 28 //deadline29 2月
          ? 30
          : daysInPreMonth === 31 &&
            this.mixinInputData.deadline === 30 &&
            daysInMonth === 28 //deadline30 2月
          ? 29
          : daysInPreMonth === 31 &&
            this.mixinInputData.deadline === 30 &&
            daysInMonth === 29 //deadline30 2月閏年
          ? 30
          : daysInMonth
      const days = []
      for (let i = 0; i < repeatNum; i++) {
        days.unshift(deadlineDay.format('YYYYMMDD'))
        deadlineDay.subtract(1, 'd')
      }
      return days
    },
    selectData() {
      const selectData = []
      for (let i = 16; i <= 31; i++) {
        const name = i !== 31 ? i : '月末'
        selectData.push({ id: i, name })
      }
      return selectData
    }
  },

  created() {
    this.mixinInputData = {
      staffWorkingDates: _.cloneDeep(
        this.$store.getters['staffWorkingDates/getData']
      ),
      deadline: this.$store.getters['shiftSetting/getData'].deadline
    }
    this.mixinSetInitialData()
    const currentDay = moment().format('DD')
    this.yearMonth =
      Number(currentDay) > this.mixinInputData.deadline
        ? moment().add(1, 'M')
        : moment()
    this.defaultShiftId = this.$store.getters['shifts/getData'].find(
      v => v.defaultFlg === 1
    ).id
    this.shiftId = this.defaultShiftId
    this.checkExistStaffWorkingDates()
  },

  mounted() {
    this.$store.dispatch('petorelu/okLeave')
  },

  methods: {
    backMonth() {
      this.yearMonth = moment(this.yearMonth).subtract(1, 'M')
      this.checkExistStaffWorkingDates()
    },
    nextMonth() {
      this.yearMonth = moment(this.yearMonth).add(1, 'M')
      this.checkExistStaffWorkingDates()
    },
    checkExistStaffWorkingDates() {
      this.staffs.forEach(staff => {
        const currentMonth = moment(this.yearMonth).format('YYYYMM')
        const existCurrentMonthStaffWorkingDate = this.mixinInputData.staffWorkingDates.some(
          v => v.yearMonth === currentMonth && v.staffId === staff.id
        )
        if (!existCurrentMonthStaffWorkingDate) {
          this.createStaffWorkingDates(staff, currentMonth)
        }
        const nextMonth = moment(this.yearMonth)
          .subtract(1, 'M')
          .format('YYYYMM')
        const existNextMonthStaffWorkingDate = this.mixinInputData.staffWorkingDates.some(
          v => v.yearMonth === nextMonth && v.staffId === staff.id
        )
        if (!existNextMonthStaffWorkingDate) {
          this.createStaffWorkingDates(staff, nextMonth)
        }
      })
    },
    createStaffWorkingDates(staff, yearMonth) {
      const makeObj = () => ({
        staffId: staff.id,
        yearMonth: moment(yearMonth).format('YYYYMM'),
        shiftIds: new Array(moment(yearMonth).daysInMonth()).fill(
          this.defaultShiftId
        )
      })
      this.mixinInputData.staffWorkingDates.push(makeObj())
      this.mixinInitialData.staffWorkingDates.push(makeObj())
    },
    findTargetStaffWorkingDates(targetShift) {
      return this.mixinInputData.staffWorkingDates.find(
        v =>
          v.staffId === targetShift.staffId &&
          v.yearMonth === targetShift.yearMonth
      )
    },
    changeShiftId(targetShift) {
      this.findTargetStaffWorkingDates(targetShift).shiftIds.splice(
        targetShift.day - 1,
        1,
        this.shiftId
      )
    },
    selectShift(shiftId) {
      this.shiftId = shiftId
    },
    bulkRegistrationShiftIds() {
      const newShiftIds = this.staffs.map(staff => {
        const shiftPattern = this.getShiftPattern(staff.shiftPatternId)
        const shiftIds = this.days.map(day => {
          const weekdayId = moment(day, 'YYYYMMDD').get('day')
          if (this.publicHolidays.indexOf(day) >= 0) {
            return shiftPattern !== undefined &&
              shiftPattern.holidayShiftId !== 0
              ? { shiftId: shiftPattern.holidayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 0) {
            return shiftPattern !== undefined &&
              shiftPattern.sundayShiftId !== 0
              ? { shiftId: shiftPattern.sundayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 1) {
            return shiftPattern !== undefined &&
              shiftPattern.mondayShiftId !== 0
              ? { shiftId: shiftPattern.mondayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 2) {
            return shiftPattern !== undefined &&
              shiftPattern.tuesdayShiftId !== 0
              ? { shiftId: shiftPattern.tuesdayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 3) {
            return shiftPattern !== undefined &&
              shiftPattern.wednesdayShiftId !== 0
              ? { shiftId: shiftPattern.wednesdayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 4) {
            return shiftPattern !== undefined &&
              shiftPattern.thursdayShiftId !== 0
              ? { shiftId: shiftPattern.thursdayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 5) {
            return shiftPattern !== undefined &&
              shiftPattern.fridayShiftId !== 0
              ? { shiftId: shiftPattern.fridayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          } else if (weekdayId === 6) {
            return shiftPattern !== undefined &&
              shiftPattern.saturdayShiftId !== 0
              ? { shiftId: shiftPattern.saturdayShiftId, day }
              : { shiftId: this.defaultShiftId, day }
          }
        })
        return { staffId: staff.id, shiftIds: shiftIds }
      })
      newShiftIds.forEach(v => {
        v.shiftIds.forEach(w => {
          const target = this.mixinInputData.staffWorkingDates.find(
            x =>
              x.staffId === v.staffId && x.yearMonth === w.day.substring(0, 6)
          )
          if (moment().subtract(1, 'd') < moment(w.day, 'YYYYMMDD')) {
            this.$set(
              target.shiftIds,
              Number(w.day.substring(6)) - 1,
              w.shiftId
            )
          }
        })
      })
    },
    bulkClearShiftIds() {
      this.staffs.forEach(staff => {
        this.days.forEach(day => {
          const target = this.mixinInputData.staffWorkingDates.find(
            v => v.staffId === staff.id && v.yearMonth === day.substring(0, 6)
          )
          if (moment().subtract(1, 'd') < moment(day, 'YYYYMMDD')) {
            this.$set(
              target.shiftIds,
              Number(day.substring(6)) - 1,
              this.defaultShiftId
            )
          }
        })
      })
    },
    async updateStaffWorkingDates() {
      this.waitFlg = true
      const obj = {
        staffWorkingDates: this.mixinInputData.staffWorkingDates,
        deadline: this.mixinInputData.deadline
      }
      const res = await this.$store.dispatch('staffWorkingDates/update', obj)
      this.mixinInputData.staffWorkingDates = _.cloneDeep(res.staffWorkingDates)
      this.mixinSetInitialData()
      if (res.status === 200) {
        this.title = '完了'
        this.type = 'success'
        this.popupMessage = '勤務表を登録しました。'
        this.leftAlignMessage = res.leftAlignMessage
        this.popupStyle = { width: '800px' }
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage =
          res.extra === 'shift'
            ? '既に削除されたシフトデータが使用されています。\n最新データに更新しますので再編集をお願いします。'
            : '登録に失敗しました'
        this.leftAlignMessage = ''
        this.popupStyle = {}
      }
      this.waitFlg = false
      this.popupFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.staff-working-dates {
  > .select-box-form {
    margin-top: 25px;
    width: 300px;
  }
  > .contents-area {
    margin-top: 10px;
    > .heading {
      margin-top: 60px;
      display: flex;
      align-items: center;
      > .text {
        font-size: 15px;
        font-weight: bold;
      }
    }
    > hr {
      margin: 20px 0 30px 0;
      height: 2px;
      background-color: #{$light-grey};
      border: none;
    }
    > .shift-select-wrapper {
      margin-bottom: 60px;
    }
    > .button {
      text-align: center;
      margin: 0px auto 60px;
    }
  }
}
</style>
